import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class CalendarService {
    sumarXDias(anio, mes, dia, x) {
        let diaTot = dia;
        let mesTot = mes;
        let anioTot = anio;
        let mesString = null;
        let diaString = null;

        if (mes === 4 || mes === 6 || mes === 9 || mes === 11) {
            for (let i = 0; i < x; i++) {
                diaTot = diaTot + 1;
                if (diaTot > 30) {
                    diaTot = 1;
                    mesTot = mesTot + 1;

                }

            }
        }
        if (mes === 1 || mes === 3 || mes === 5 || mes === 7 || mes === 8 || mes === 10) {
            for (let i = 0; i < x; i++) {
                diaTot = diaTot + 1;
                if (diaTot > 31) {
                    diaTot = 1;
                    mesTot = mesTot + 1;

                }
            }
        }
        if (mes === 2) {
            for (let i = 0; i < x; i++) {
                diaTot = diaTot + 1;
                if (anio % 4 === 0) {
                    if (diaTot > 29) {
                        diaTot = 1;
                        mesTot = mesTot + 1;

                    }
                }
                else {

                    if (diaTot > 28) {
                        diaTot = 1;
                        mesTot = mesTot + 1;

                    }

                }
            }

        }

        if (mes == 12) {
            for (let i = 0; i < x; i++) {
                diaTot = diaTot + 1;
                if (diaTot > 31) {
                    mesTot = 1;
                    diaTot = 1;
                    anioTot = anioTot + 1;
                }
            }
        }

        if (mesTot < 10) {
            mesString = "0" + mesTot;
        }
        else {
            mesString = mesTot;
        }

        if (diaTot < 10) {
            diaString = "0" + diaTot;
        }
        else {
            diaString = diaTot;
        }
        let fecha = anioTot + "-" + mesString + "-" + diaString;

        return fecha;
    }

    constructor(private httpclient: HttpClientModule) {
    }



}
