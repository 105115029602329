import { Component, Injectable, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Sociedades } from './model/sociedades.interface';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Constants } from './globals';
import { last } from 'rxjs/operators';
import { AppPage } from 'e2e/src/app.po';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  d = new Date();
  anio = this.d.getFullYear();
  dia = this.d.getDate();
  mes = this.d.getMonth() + 1;
  hoy = (this.anio + "-" + this.mes + "-" + this.dia);
  firebaseStorage = Constants.FIREBASE_URL;
  idSocio = localStorage.getItem("idSocio");
  variable = false;
  direccion = Constants.API_URL;
  public appPages = [
    {
      title: 'Inicio',
      url: '/inicio',
      icon: 'home'
    },
    {
      title: 'Reservas',
      url: '/reservas',
      icon: 'add-circle'
    },

    {
      title: 'Productos',
      url: '/productos',
      icon: 'wine'
    },

    {
      title: 'Facturas',
      url: '/facturas-usuario',
      icon: 'clipboard'
    },

    {
      title: 'Mi sociedad',
      url: '/mi-sociedad',
      icon: 'people'
    },
    {
      title: 'Perfil',
      url: '/perfil',
      icon: 'person'
    },
    {
      title: 'Seguridad',
      url: '/seguridad',
      icon: 'lock'
    },





  ];


  constructor(
    private httpclient: HttpClient,
    private activateRoute: ActivatedRoute,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,

  ) {

    this.initializeApp();
    this.getDatos();
    this.usuario();
  }


  fecha() {

  }
  salir() {
    let url = this.direccion + `eliminarSesionActiva/${encodeURI(localStorage.getItem("idSocio"))}/${encodeURI(localStorage.getItem("idSociedad"))}/${encodeURI(localStorage.getItem("tipoUsuario"))}/${encodeURI(localStorage.getItem("token"))}`;

    this.getSociedad(url).subscribe
    (data => {
      //TENEMOS QUE ESPERAR A LA RESPUESTA DE LA API, SI NO, MISTERIOSAMENTE NO ELIMINA EL REGISTRO DE LA BASE DE DATOS.
      if(data[0].respuesta=="si"){
        location.assign('/');
      }
      else{
        location.assign('/');
      }



    });
    localStorage.clear();
   
  }
  listSociedades: Sociedades[];
  listUsuarios: Sociedades[];
  getSociedad(url): Observable<any> {
    return this.httpclient.get(url);
  }
  getUsuarios(url): Observable<any> {
    return this.httpclient.get(url);
  }
  getDatos() {
    let url = this.direccion + `usuarios_sociedad/${encodeURI(localStorage.getItem("idSocio"))}`;
    this.getSociedad(url).subscribe
      (data => {
        this.listSociedades = data;





      });
  }

  usuario() {
    let url = this.direccion + `usuariosPorId/${encodeURI(localStorage.getItem("idSocio"))}`;
    this.getSociedad(url).subscribe
      (data => {
        this.listUsuarios = data;
        if (this.listUsuarios[0]["tipo_usuario"] == "1") {
          this.variable = true;
          let ultima = this.appPages.length - 1;
          this.appPages.splice(ultima, 0, {

            title: 'Administración',
            url: '/administracion',
            icon: 'stats'
          });

        }

        if (this.listUsuarios[0]["tipo_usuario"] == "4") {
          this.variable = true;
          let ultima = this.appPages.length - 1;
          this.appPages.splice(ultima, 0, {

            title: 'Administración',
            url: '/administracion',
            icon: 'stats'
          },
            {
              title: 'Super Usuario',
              url: '/super-usuario',
              icon: 'people'
            });

        }




      });
  }


  initializeApp() {

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
}
