import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'inicio', loadChildren: './inicio/inicio.module#InicioPageModule' },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'nueva-reserva/:fecha', loadChildren: './nueva-reserva/nueva-reserva.module#NuevaReservaPageModule', canActivate: [AuthGuard] },
  { path: 'perfil', loadChildren: './perfil/perfil.module#PerfilPageModule', canActivate: [AuthGuard] },
  { path: 'productos', loadChildren: './home/home.module#HomePageModule', canActivate: [AuthGuard] },
  { path: 'registro/:idSociedad', loadChildren: './registro/registro.module#RegistroPageModule' },
  { path: 'registro-sociedad', loadChildren: './registro-sociedad/registro-sociedad.module#RegistroSociedadPageModule' },
 
  { path: 'tutorial', loadChildren: './tutorial/tutorial.module#TutorialPageModule' },
  { path: 'editarperfil', loadChildren: './editarperfil/editarperfil.module#EditarperfilPageModule', canActivate: [AuthGuard] },
  { path: 'seguridad', loadChildren: './seguridad/seguridad.module#SeguridadPageModule', canActivate: [AuthGuard] },
  { path: 'administracion', loadChildren: './administracion/administracion.module#AdministracionPageModule', canActivate: [AuthGuard] },
  { path: 'usuarios-admin', loadChildren: './usuarios-admin/usuarios-admin.module#UsuariosAdminPageModule', canActivate: [AuthGuard] },
  { path: 'mesas-admin', loadChildren: './mesas-admin/mesas-admin.module#MesasAdminPageModule', canActivate: [AuthGuard] },
  { path: 'usuarios-admin-detalle/:idSocio', loadChildren: './usuarios-admin-detalle/usuarios-admin-detalle.module#UsuariosAdminDetallePageModule', canActivate: [AuthGuard] },
  { path: 'mesas-admin-detalle/:idSocio', loadChildren: './mesas-admin-detalle/mesas-admin-detalle.module#MesasAdminDetallePageModule', canActivate: [AuthGuard] },
  { path: 'carrito', loadChildren: './carrito/carrito.module#CarritoPageModule', canActivate: [AuthGuard] },
  { path: 'factura-detalle/:idSocio', loadChildren: './factura-detalle/factura-detalle.module#FacturaDetallePageModule', canActivate: [AuthGuard] },
  { path: 'facturas', loadChildren: './facturas/facturas.module#FacturasPageModule', canActivate: [AuthGuard] },
  { path: 'mi-sociedad', loadChildren: './mi-sociedad/mi-sociedad.module#MiSociedadPageModule', canActivate: [AuthGuard] },
  { path: 'mi-sociedad-detalle/:idSocio', loadChildren: './mi-sociedad-detalle/mi-sociedad-detalle.module#MiSociedadDetallePageModule', canActivate: [AuthGuard] },
  { path: 'productos-admin', loadChildren: './productos-admin/productos-admin.module#ProductosAdminPageModule', canActivate: [AuthGuard] },
  { path: 'productos-admin-detalle/:idSocio', loadChildren: './productos-admin-detalle/productos-admin-detalle.module#ProductosAdminDetallePageModule', canActivate: [AuthGuard] },
  { path: 'anadir-producto', loadChildren: './anadir-producto/anadir-producto.module#AnadirProductoPageModule', canActivate: [AuthGuard] },
  { path: 'seguridad-admin', loadChildren: './seguridad-admin/seguridad-admin.module#SeguridadAdminPageModule', canActivate: [AuthGuard] },
  { path: 'sociedades', loadChildren: './sociedades/sociedades.module#SociedadesPageModule', canActivate: [AuthGuard] },
  { path: 'sociedades-detalle/:idSociedad', loadChildren: './sociedades-detalle/sociedades-detalle.module#SociedadesDetallePageModule', canActivate: [AuthGuard] },
  { path: 'sociedades-usuario', loadChildren: './sociedades-usuario/sociedades-usuario.module#SociedadesUsuarioPageModule', canActivate: [AuthGuard] },
  { path: 'super-usuario', loadChildren: './super-usuario/super-usuario.module#SuperUsuarioPageModule', canActivate: [AuthGuard] },
  { path: 'sociedades-usuario-detalle/:idSocio', loadChildren: './sociedades-usuario-detalle/sociedades-usuario-detalle.module#SociedadesUsuarioDetallePageModule', canActivate: [AuthGuard] },
  { path: 'anadir-usuario', loadChildren: './anadir-usuario/anadir-usuario.module#AnadirUsuarioPageModule', canActivate: [AuthGuard] },
  { path: 'reservas', loadChildren: './reservas/reservas.module#ReservasPageModule', canActivate: [AuthGuard] },
  { path: 'mis-reservas', loadChildren: './mis-reservas/mis-reservas.module#MisReservasPageModule', canActivate: [AuthGuard] },
  { path: 'reservas-sociedad', loadChildren: './reservas-sociedad/reservas-sociedad.module#ReservasSociedadPageModule', canActivate: [AuthGuard] },
  { path: 'facturas-usuario', loadChildren: './facturas-usuario/facturas-usuario.module#FacturasUsuarioPageModule', canActivate: [AuthGuard] },
  { path: 'opciones-sociedad', loadChildren: './opciones-sociedad/opciones-sociedad.module#OpcionesSociedadPageModule', canActivate: [AuthGuard] },
  { path: 'dias-antelacion', loadChildren: './dias-antelacion/dias-antelacion.module#DiasAntelacionPageModule', canActivate: [AuthGuard] },
  { path: 'mesas', loadChildren: './mesas/mesas.module#MesasPageModule', canActivate: [AuthGuard] },
  { path: 'categorias-admin', loadChildren: './categorias-admin/categorias-admin.module#CategoriasAdminPageModule', canActivate: [AuthGuard] },
  { path: 'categorias-admin-detalle/:id', loadChildren: './categorias-admin-detalle/categorias-admin-detalle.module#CategoriasAdminDetallePageModule', canActivate: [AuthGuard] },
  { path: 'facturas-por-mes', loadChildren: './facturas-por-mes/facturas-por-mes.module#FacturasPorMesPageModule', canActivate: [AuthGuard] },
  { path: 'facturas-por-mes-admin', loadChildren: './facturas-por-mes-admin/facturas-por-mes-admin.module#FacturasPorMesAdminPageModule', canActivate: [AuthGuard] },































];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
