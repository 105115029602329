import { NgModule,LOCALE_ID  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { registerLocaleData } from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { PipePipe } from './pipes/pipe.pipe';
import { FiltroPipe } from './pipes/filtro.pipe';

import {firebaseConfig} from '../environments/environment'
import { CalendarService } from './services/calendar.service';
import { AngularFireModule } from "@angular/fire";
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FileTransfer} from '@ionic-native/file-transfer/ngx';
import {NgCalendarModule} from 'ionic2-calendar';

registerLocaleData(es);

@NgModule({
  declarations: [AppComponent, PipePipe, FiltroPipe],
  entryComponents: [],
  
  imports: [
    
    BrowserModule,
    ReactiveFormsModule,
    AngularFireStorageModule,
    NgCalendarModule,
    AngularFireModule.initializeApp(firebaseConfig),
    IonicModule.forRoot(),
    AppRoutingModule,
  
    HttpClientModule
   
    
  ],
  providers: [
    
    StatusBar,
    FileTransfer,
    SplashScreen,
    FiltroPipe,
 
    CalendarService,
   
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
