import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

let tipoUsuario = localStorage.getItem("tipoUsuario");

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private router: Router){

  }
  canActivate(logged){
 
      if(parseInt(tipoUsuario)==1 || parseInt(tipoUsuario)==4 ||  parseInt(tipoUsuario)==3 ){
        
        
        return true;
        
      }
      
       
        location.assign("/");
        return false;
      
    
  }



}
